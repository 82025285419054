<template>
  <div class="vx-col w-full">
    <div class="vx-row">
      <div class="vx-col w-full">
        <vs-prompt title="Export To Excel" class="export-options" @cancle="clearFields" @accept="exportToExcel"
          accept-text="Export" @close="clearFields" :active.sync="activePrompt">
          <vs-input v-model="fileName" placeholder="Enter File Name.." class="w-full" />
          <v-select v-model="selectedFormat" :options="formats" class="my-4" />
          <div class="flex">
            <span class="mr-4">Cell Auto Width:</span>
            <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
          </div>
        </vs-prompt>

        <vx-card title="เลือกวันเวลา">
          <label>เริ่มต้น: </label>
          <flat-pickr :config="configdateTimePicker" v-model="before_datetime" placeholder="Date Time" />
          <br>
          <br>
          <label> สิ้นสุด: </label>
          <flat-pickr :config="configdateTimePicker" v-model="after_datetime" placeholder="Date Time" />
          <br>
          <br>
          <vs-button  color="primary" @click="selectdate_time ()">ค้นหา</vs-button>
        </vx-card>

      </div>
    </div>
        <vs-prompt class="export-options" @cancle="clearFields" @accept="exportToExcel(data[0],'ฝาก 200 รับ 500')" accept-text="Export" @close="clearFields" :active.sync="activePrompt1" title="ฝาก 200 รับ 500">
        <vs-input v-model="fileName" placeholder="Enter File Name.."  class="w-full" />
        <v-select v-model="selectedFormat" :options="formats" class="my-4" />
        <div class="flex">
          <span class="mr-4">Cell Auto Width:</span>
          <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
        </div>
    </vs-prompt>
  <vs-prompt class="export-options" @cancle="clearFields" @accept="exportToExcel(data[1],'ฝาก 500 ได้ 1500')" accept-text="Export" @close="clearFields" :active.sync="activePrompt2" title="ฝาก 500 ได้ 1500">
        <vs-input v-model="fileName" placeholder="Enter File Name.."  class="w-full" />
        <v-select v-model="selectedFormat" :options="formats" class="my-4" />
        <div class="flex">
          <span class="mr-4">Cell Auto Width:</span>
          <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
        </div>
    </vs-prompt>
     <vs-prompt class="export-options" @cancle="clearFields" @accept="exportToExcel(data[2],'โปรโมชั่นสมาชิกใหม่ 100%')" accept-text="Export" @close="clearFields" :active.sync="activePrompt3" title="โปรโมชั่นสมาชิกใหม่ 100%">
        <vs-input v-model="fileName" placeholder="Enter File Name.."  class="w-full" />
        <v-select v-model="selectedFormat" :options="formats" class="my-4" />
        <div class="flex">
          <span class="mr-4">Cell Auto Width:</span>
          <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
        </div>
    </vs-prompt>
     <vs-prompt class="export-options" @cancle="clearFields" @accept="exportToExcel(data[3],'โปรโมชันฝากครั้งแรกของวัน รับ 30%')" accept-text="Export" @close="clearFields" :active.sync="activePrompt4" title="โปรโมชันฝากครั้งแรกของวัน รับ 30%">
        <vs-input v-model="fileName" placeholder="Enter File Name.."  class="w-full" />
        <v-select v-model="selectedFormat" :options="formats" class="my-4" />
        <div class="flex">
          <span class="mr-4">Cell Auto Width:</span>
          <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
        </div>
    </vs-prompt>
     <vs-prompt class="export-options" @cancle="clearFields" @accept="exportToExcel(data[4],'โปรโมชัน ฝาก 500 รับ 600 บาท')" accept-text="Export" @close="clearFields" :active.sync="activePrompt5" title="โปรโมชัน ฝาก 500 รับ 600 บาท">
        <vs-input v-model="fileName" placeholder="Enter File Name.."  class="w-full" />
        <v-select v-model="selectedFormat" :options="formats" class="my-4" />
        <div class="flex">
          <span class="mr-4">Cell Auto Width:</span>
          <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
        </div>
    </vs-prompt>
     <vs-prompt class="export-options" @cancle="clearFields" @accept="exportToExcel(data[5],'แนะนำเพื่อน')" accept-text="Export" @close="clearFields" :active.sync="activePrompt6" title="แนะนำเพื่อน">
        <vs-input v-model="fileName" placeholder="Enter File Name.."  class="w-full" />
        <v-select v-model="selectedFormat" :options="formats" class="my-4" />
        <div class="flex">
          <span class="mr-4">Cell Auto Width:</span>
          <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
        </div>
    </vs-prompt>
    <vs-prompt class="export-options" @cancle="clearFields" @accept="exportToExcel(data[6],'คืนยอดเล่น')" accept-text="Export" @close="clearFields" :active.sync="activePrompt7" title="คืนยอดเล่น">
        <vs-input v-model="fileName" placeholder="Enter File Name.."  class="w-full" />
        <v-select v-model="selectedFormat" :options="formats" class="my-4" />
        <div class="flex">
          <span class="mr-4">Cell Auto Width:</span>
          <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
        </div>
    </vs-prompt>
      <vs-prompt class="export-options" @cancle="clearFields" @accept="exportToExcel(data[9],'ranking')" accept-text="Export" @close="clearFields" :active.sync="activePrompt8" title="ranking">
        <vs-input v-model="fileName" placeholder="Enter File Name.."  class="w-full" />
        <v-select v-model="selectedFormat" :options="formats" class="my-4" />
        <div class="flex">
          <span class="mr-4">Cell Auto Width:</span>
          <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
        </div>
    </vs-prompt>
         <vs-prompt class="export-options" @cancle="clearFields" @accept="exportToExcel(data[10],'โปรโมชั่นสมาชิกใหม่ 50%')" accept-text="Export" @close="clearFields" :active.sync="activePrompt9" title="โปรโมชั่นสมาชิกใหม่ 50%">
        <vs-input v-model="fileName" placeholder="Enter File Name.."  class="w-full" />
        <v-select v-model="selectedFormat" :options="formats" class="my-4" />
        <div class="flex">
          <span class="mr-4">Cell Auto Width:</span>
          <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
        </div>
    </vs-prompt>
    <vs-prompt class="export-options" @cancle="clearFields" @accept="exportToExcel(data[11],'โปรโมชั่น 9 เดือน 9')" accept-text="Export" @close="clearFields" :active.sync="activePrompt14" title="โปรโมชั่น 9 เดือน 9">
        <vs-input v-model="fileName" placeholder="Enter File Name.."  class="w-full" />
        <v-select v-model="selectedFormat" :options="formats" class="my-4" />
        <div class="flex">
          <span class="mr-4">Cell Auto Width:</span>
          <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
        </div>
    </vs-prompt>
    <vs-prompt class="export-options" @cancle="clearFields" @accept="exportToExcel(data[6],'โปรโมชั่นเทพ SLOT')" accept-text="Export" @close="clearFields" :active.sync="activePrompt15" title="โปรโมชั่นเทพ SLOT">
        <vs-input v-model="fileName" placeholder="Enter File Name.."  class="w-full" />
        <v-select v-model="selectedFormat" :options="formats" class="my-4" />
        <div class="flex">
          <span class="mr-4">Cell Auto Width:</span>
          <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
        </div>
    </vs-prompt>
    <vs-prompt class="export-options" @cancle="clearFields" @accept="exportToExcel(data[12],'โปรโมชันเก๊าจิ้งเมืองไทย')" accept-text="Export" @close="clearFields" :active.sync="activePrompt16" title="โปรโมชันเก๊าจิ้งเมืองไทย">
        <vs-input v-model="fileName" placeholder="Enter File Name.."  class="w-full" />
        <v-select v-model="selectedFormat" :options="formats" class="my-4" />
        <div class="flex">
          <span class="mr-4">Cell Auto Width:</span>
          <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
        </div>
    </vs-prompt>
    <vs-prompt class="export-options" @cancle="clearFields" @accept="exportToExcel(data[2],'สมาชิกใหม่ รับโบนัส 100%')" accept-text="Export" @close="clearFields" :active.sync="activePrompt17" title="สมาชิกใหม่ รับโบนัส 100%">
        <vs-input v-model="fileName" placeholder="Enter File Name.."  class="w-full" />
        <v-select v-model="selectedFormat" :options="formats" class="my-4" />
        <div class="flex">
          <span class="mr-4">Cell Auto Width:</span>
          <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
        </div>
    </vs-prompt>
    <vs-prompt class="export-options" @cancle="clearFields" @accept="exportToExcel(data[3],'ฝากครั้งแรกของวัน รับโบนัส 30%')" accept-text="Export" @close="clearFields" :active.sync="activePrompt18" title="ฝากครั้งแรกของวัน รับโบนัส 30%">
        <vs-input v-model="fileName" placeholder="Enter File Name.."  class="w-full" />
        <v-select v-model="selectedFormat" :options="formats" class="my-4" />
        <div class="flex">
          <span class="mr-4">Cell Auto Width:</span>
          <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
        </div>
    </vs-prompt>
    <vs-prompt class="export-options" @cancle="clearFields" @accept="exportToExcel(data[14],'โปรโมชันคืนยอดเสีย 10%')" accept-text="Export" @close="clearFields" :active.sync="activePrompt19" title="โปรโมชันคืนยอดเสีย 10%">
        <vs-input v-model="fileName" placeholder="Enter File Name.."  class="w-full" />
        <v-select v-model="selectedFormat" :options="formats" class="my-4" />
        <div class="flex">
          <span class="mr-4">Cell Auto Width:</span>
          <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
        </div>
    </vs-prompt>
    <vs-prompt class="export-options" @cancle="clearFields" @accept="exportToExcel(data[4],'โปรโมชั่น 10% รับได้ตลอดทั้งวัน')" accept-text="Export" @close="clearFields" :active.sync="activePrompt20" title="โปรโมชั่น 10% รับได้ตลอดทั้งวัน">
        <vs-input v-model="fileName" placeholder="Enter File Name.."  class="w-full" />
        <v-select v-model="selectedFormat" :options="formats" class="my-4" />
        <div class="flex">
          <span class="mr-4">Cell Auto Width:</span>
          <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
        </div>
    </vs-prompt>
    <vs-prompt class="export-options" @cancle="clearFields" @accept="exportToExcel(data[15],'โปรโมชั่น ฝาก 19 รับ 100')" accept-text="Export" @close="clearFields" :active.sync="activePrompt21" title="โปรโมชั่น ฝาก 19 รับ 100">
        <vs-input v-model="fileName" placeholder="Enter File Name.."  class="w-full" />
        <v-select v-model="selectedFormat" :options="formats" class="my-4" />
        <div class="flex">
          <span class="mr-4">Cell Auto Width:</span>
          <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
        </div>
    </vs-prompt>
    <vs-prompt class="export-options" @cancle="clearFields" @accept="exportToExcel(data[16],'โปรโมชั่น ฝาก 49 รับ 200')" accept-text="Export" @close="clearFields" :active.sync="activePrompt22" title="โปรโมชั่น ฝาก 49 รับ 200">
        <vs-input v-model="fileName" placeholder="Enter File Name.."  class="w-full" />
        <v-select v-model="selectedFormat" :options="formats" class="my-4" />
        <div class="flex">
          <span class="mr-4">Cell Auto Width:</span>
          <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
        </div>
    </vs-prompt>
    <vs-prompt class="export-options" @cancle="clearFields" @accept="exportToExcel(data[17],'โปรโมชั่น ฝาก 99 รับ 400')" accept-text="Export" @close="clearFields" :active.sync="activePrompt23" title="โปรโมชั่น ฝาก 99 รับ 400">
        <vs-input v-model="fileName" placeholder="Enter File Name.."  class="w-full" />
        <v-select v-model="selectedFormat" :options="formats" class="my-4" />
        <div class="flex">
          <span class="mr-4">Cell Auto Width:</span>
          <vs-switch v-model="cellAutoWidth">Cell Auto Width</vs-switch>
        </div>
    </vs-prompt>
    <br>
  <vx-card>
  <div class="vx-row" >
    <div class="vx-col md:w-1/5 w-full">Type</div>
    <div class="vx-col md:w-1/5 w-full">Bonus</div>
    <div class="vx-col md:w-1/5 w-full">User</div>
    <div class="vx-col md:w-1/5 w-full">Credit</div>
    <div class="vx-col md:w-1/5 w-full"></div>
  </div>
  <br>
  <br>
  <div v-if="webname.includes('SLOTVVIP888')" class="vx-row" >
    <div class="vx-col md:w-1/5 w-full text-danger">โปรโมชั่น 9.9 รับโบนัสเพิ่ม 100% </div>
    <div class="vx-col md:w-1/5 w-full text-danger">{{rpbonus.countbonusid14}} ครั้ง</div>
     <div class="vx-col md:w-1/5 w-full text-danger">{{rpbonus.countuserid14}} คน</div>
    <div class="vx-col md:w-1/5 w-full text-danger">{{rpbonus.sumbonusid14}} Credit</div>
    <div class="vx-col md:w-1/5 w-full text-danger"><vs-button :disabled="$store.state.AppActiveUser.permissions.report.action ? false : true" @click="activePrompt14 = true">Export</vs-button></div>
  </div>
  <br>
  <div v-if="webname.includes('SLOTVVIP888')" class="vx-row" >
    <div class="vx-col md:w-1/5 w-full text-success">โปรโมชั่นเทพ SLOT</div>
    <div class="vx-col md:w-1/5 w-full text-success">{{rpbonus.countbonusid12}} ครั้ง</div>
    <div class="vx-col md:w-1/5 w-full text-success">{{rpbonus.countuserid12}} คน</div>
    <div class="vx-col md:w-1/5 w-full text-success">{{rpbonus.sumbonusid12}} Credit</div>
    <div class="vx-col md:w-1/5 w-full text-success"><vs-button :disabled="$store.state.AppActiveUser.permissions.report.action ? false : true" @click="activePrompt15 = true">Export</vs-button></div>
  </div>
   <div v-else class="vx-row" >
    <div class="vx-col md:w-1/5 w-full text-warning">ฝาก 200 รับ 500</div>
    <div class="vx-col md:w-1/5 w-full text-warning">{{rpbonus.countbonusid8}} ครั้ง</div>
     <div class="vx-col md:w-1/5 w-full text-warning">{{rpbonus.countuserid8}} คน</div>
    <div class="vx-col md:w-1/5 w-full text-warning">{{rpbonus.sumbonusid8}} Credit</div>
    <div class="vx-col md:w-1/5 w-full text-warning"><vs-button :disabled="$store.state.AppActiveUser.permissions.report.action ? false : true" @click="activePrompt1 = true">Export</vs-button></div>
  </div>
  <br>
  <div v-if="webname.includes('SLOTVVIP888')" class="vx-row" >
    <div class="vx-col md:w-1/5 w-full text-warning">โปรโมชันเก๊าจิ้งเมืองไทย</div>
    <div class="vx-col md:w-1/5 w-full text-warning">{{rpbonus.countbonusid13}} ครั้ง</div>
    <div class="vx-col md:w-1/5 w-full text-warning">{{rpbonus.countuserid13}} คน</div>
    <div class="vx-col md:w-1/5 w-full text-warning">{{rpbonus.sumbonusid13}} Credit</div>
    <div class="vx-col md:w-1/5 w-full text-warning"><vs-button :disabled="$store.state.AppActiveUser.permissions.report.action ? false : true" @click="activePrompt16 = true">Export</vs-button></div>
  </div>
  <div v-else class="vx-row" >
    <div class="vx-col md:w-1/5 w-full text-success">ฝาก 500 ได้ 1500</div>
    <div class="vx-col md:w-1/5 w-full text-success">{{rpbonus.countbonusid9}} ครั้ง</div>
     <div class="vx-col md:w-1/5 w-full text-success">{{rpbonus.countuserid9}} คน</div>
    <div class="vx-col md:w-1/5 w-full text-success">{{rpbonus.sumbonusid9}} Credit</div>
    <div class="vx-col md:w-1/5 w-full text-success"><vs-button :disabled="$store.state.AppActiveUser.permissions.report.action ? false : true" @click="activePrompt2 = true">Export</vs-button></div>
  </div>
  <br>
  <div v-if="webname.includes('SLOTVVIP888')" class="vx-row" >
    <div class="vx-col md:w-1/5 w-full text-danger">สมาชิกใหม่ รับโบนัส 100%</div>
    <div class="vx-col md:w-1/5 w-full text-danger">{{rpbonus.countbonusid8}} ครั้ง</div>
    <div class="vx-col md:w-1/5 w-full text-danger">{{rpbonus.countuserid8}} คน</div>
    <div class="vx-col md:w-1/5 w-full text-danger">{{rpbonus.sumbonusid8}} Credit</div>
    <div class="vx-col md:w-1/5 w-full text-danger"><vs-button :disabled="$store.state.AppActiveUser.permissions.report.action ? false : true" @click="activePrompt17 = true">Export</vs-button></div>
  </div>
  <div v-else class="vx-row" >
    <div class="vx-col md:w-1/5 w-full text-danger">โปรโมชั่นสมาชิกใหม่ 100%</div>
    <div class="vx-col md:w-1/5 w-full text-danger">{{rpbonus.countbonusid10}} ครั้ง</div>
     <div class="vx-col md:w-1/5 w-full text-danger">{{rpbonus.countuserid10}} คน</div>
    <div class="vx-col md:w-1/5 w-full text-danger">{{rpbonus.sumbonusid10}} Credit</div>
    <div class="vx-col md:w-1/5 w-full text-danger"><vs-button :disabled="$store.state.AppActiveUser.permissions.report.action ? false : true" @click="activePrompt3 = true">Export</vs-button></div>
  </div>
  <br>
  <div v-if="webname.includes('SLOTVVIP888')" class="vx-row" >
    <div class="vx-col md:w-1/5 w-full text-success">ฝากครั้งแรกของวัน รับโบนัส 30%</div>
    <div class="vx-col md:w-1/5 w-full text-success">{{rpbonus.countbonusid9}} ครั้ง</div>
    <div class="vx-col md:w-1/5 w-full text-success">{{rpbonus.countuserid9}} คน</div>
    <div class="vx-col md:w-1/5 w-full text-success">{{rpbonus.sumbonusid9}} Credit</div>
    <div class="vx-col md:w-1/5 w-full text-success"><vs-button :disabled="$store.state.AppActiveUser.permissions.report.action ? false : true" @click="activePrompt18 = true">Export</vs-button></div>
  </div>
  <div v-else class="vx-row" >
    <div class="vx-col md:w-1/5 w-full text-warning">โปรโมชันฝากครั้งแรกของวัน รับ 30%</div>
    <div class="vx-col md:w-1/5 w-full text-warning">{{rpbonus.countbonusid11}} ครั้ง</div>
     <div class="vx-col md:w-1/5 w-full text-warning">{{rpbonus.countuserid11}} คน</div>
    <div class="vx-col md:w-1/5 w-full text-warning">{{rpbonus.sumbonusid11}} Credit</div>
    <div class="vx-col md:w-1/5 w-full text-warning"><vs-button :disabled="$store.state.AppActiveUser.permissions.report.action ? false : true" @click="activePrompt4 = true">Export</vs-button></div>
  </div>
  <br>
  <div v-if="webname.includes('SLOTVVIP888')" class="vx-row" >
    <div class="vx-col md:w-1/5 w-full text-warning">โปรโมชันคืนยอดเสีย 10%</div>
    <div class="vx-col md:w-1/5 w-full text-warning">{{rpbonus.countbonusid15}} ครั้ง</div>
    <div class="vx-col md:w-1/5 w-full text-warning">{{rpbonus.countuserid15}} คน</div>
    <div class="vx-col md:w-1/5 w-full text-warning">{{rpbonus.sumbonusid15}} Credit</div>
    <div class="vx-col md:w-1/5 w-full text-warning"><vs-button :disabled="$store.state.AppActiveUser.permissions.report.action ? false : true" @click="activePrompt19 = true">Export</vs-button></div>
  </div>
  <div v-else class="vx-row" >
    <div class="vx-col md:w-1/5 w-full text-success">โปรโมชัน ฝาก 500 รับ 600 บาท</div>
    <div class="vx-col md:w-1/5 w-full text-success">{{rpbonus.countbonusid12}} ครั้ง</div>
     <div class="vx-col md:w-1/5 w-full text-success">{{rpbonus.countuserid12}} คน</div>
    <div class="vx-col md:w-1/5 w-full text-success">{{rpbonus.sumbonusid12}} Credit</div>
    <div class="vx-col md:w-1/5 w-full text-success"><vs-button :disabled="$store.state.AppActiveUser.permissions.report.action ? false : true" @click="activePrompt5 = true">Export</vs-button></div>
  </div>
  <br>
  <div v-if="webname.includes('SLOTVVIP888')" class="vx-row" >
    <div class="vx-col md:w-1/5 w-full text-warning">โปรโมชั่น 10% รับได้ตลอดทั้งวัน</div>
    <div class="vx-col md:w-1/5 w-full text-warning">{{rpbonus.countbonusid10}} ครั้ง</div>
    <div class="vx-col md:w-1/5 w-full text-warning">{{rpbonus.countuserid10}} คน</div>
    <div class="vx-col md:w-1/5 w-full text-warning">{{rpbonus.sumbonusid10}} Credit</div>
    <div class="vx-col md:w-1/5 w-full text-warning"><vs-button :disabled="$store.state.AppActiveUser.permissions.report.action ? false : true" @click="activePrompt20 = true">Export</vs-button></div>
  </div>
  <br>
  <div v-if="webname.includes('SLOTVVIP888')" class="vx-row" >
    <div class="vx-col md:w-1/5 w-full text-warning">โปรโมชั่น ฝาก 19 รับ 100</div>
    <div class="vx-col md:w-1/5 w-full text-warning">{{rpbonus.countbonusid16}} ครั้ง</div>
    <div class="vx-col md:w-1/5 w-full text-warning">{{rpbonus.countuserid16}} คน</div>
    <div class="vx-col md:w-1/5 w-full text-warning">{{rpbonus.sumbonusid16}} Credit</div>
    <div class="vx-col md:w-1/5 w-full text-warning"><vs-button :disabled="$store.state.AppActiveUser.permissions.report.action ? false : true" @click="activePrompt21 = true">Export</vs-button></div>
  </div>
  <br>
  <div v-if="webname.includes('SLOTVVIP888')" class="vx-row" >
    <div class="vx-col md:w-1/5 w-full text-warning">โปรโมชั่น ฝาก 49 รับ 200</div>
    <div class="vx-col md:w-1/5 w-full text-warning">{{rpbonus.countbonusid17}} ครั้ง</div>
    <div class="vx-col md:w-1/5 w-full text-warning">{{rpbonus.countuserid17}} คน</div>
    <div class="vx-col md:w-1/5 w-full text-warning">{{rpbonus.sumbonusid17}} Credit</div>
    <div class="vx-col md:w-1/5 w-full text-warning"><vs-button :disabled="$store.state.AppActiveUser.permissions.report.action ? false : true" @click="activePrompt22 = true">Export</vs-button></div>
  </div>
  <br>
  <div v-if="webname.includes('SLOTVVIP888')" class="vx-row" >
    <div class="vx-col md:w-1/5 w-full text-warning">โปรโมชั่น ฝาก 99 รับ 400</div>
    <div class="vx-col md:w-1/5 w-full text-warning">{{rpbonus.countbonusid18}} ครั้ง</div>
    <div class="vx-col md:w-1/5 w-full text-warning">{{rpbonus.countuserid18}} คน</div>
    <div class="vx-col md:w-1/5 w-full text-warning">{{rpbonus.sumbonusid18}} Credit</div>
    <div class="vx-col md:w-1/5 w-full text-warning"><vs-button :disabled="$store.state.AppActiveUser.permissions.report.action ? false : true" @click="activePrompt23 = true">Export</vs-button></div>
  </div>
  <br>
  <div class="vx-row" >
    <div class="vx-col md:w-1/5 w-full text-danger">แนะนำเพื่อน</div>
    <div class="vx-col md:w-1/5 w-full text-danger">{{rpbonus.countaff}} ครั้ง</div>
    <div class="vx-col md:w-1/5 w-full text-danger">{{rpbonus.countuseraff}} คน</div>
    <div class="vx-col md:w-1/5 w-full text-danger">{{rpbonus.sumaff}} Credit</div>
    <div class="vx-col md:w-1/5 w-full text-danger"><vs-button :disabled="$store.state.AppActiveUser.permissions.report.action ? false : true" @click="activePrompt6 = true">Export</vs-button></div>
  </div>
  <br>
  <div class="vx-row" >
    <div class="vx-col md:w-1/5 w-full text-warning">คืนเครดิต</div>
    <div class="vx-col md:w-1/5 w-full text-warning">{{rpbonus.countturn}} ครั้ง</div>
    <div class="vx-col md:w-1/5 w-full text-warning">{{rpbonus.countuserturn}} คน</div>
    <div class="vx-col md:w-1/5 w-full text-warning">{{rpbonus.sumturn}} Credit</div>
    <div class="vx-col md:w-1/5 w-full text-warning"><vs-button :disabled="$store.state.AppActiveUser.permissions.report.action ? false : true" @click="activePrompt7 = true">Export</vs-button></div>
  </div>
  <br>
  <div class="vx-row" >
    <div class="vx-col md:w-1/5 w-full text-success">เปิดการ์ดลุ้นโชค</div>
    <div class="vx-col md:w-1/5 w-full text-success">{{rpbonus.countcard}} ครั้ง</div>
    <div class="vx-col md:w-1/5 w-full text-success">{{rpbonus.countusercard}} คน</div>
    <div class="vx-col md:w-1/5 w-full text-success">{{rpbonus.sumcard}} Credit</div>
  </div>
  <br>
  <br>
  <div class="vx-row" >
    <div class="vx-col md:w-1/5 w-full text-danger">เปิดกงล้อลุ้นโชค</div>
    <div class="vx-col md:w-1/5 w-full text-danger">{{rpbonus.countspin}} ครั้ง</div>
    <div class="vx-col md:w-1/5 w-full text-danger">{{rpbonus.countuserspin}} คน</div>
    <div class="vx-col md:w-1/5 w-full text-danger">{{rpbonus.sumspin}} Credit</div>
  </div>
  <br>
  <br>
  <div class="vx-row" >
    <div class="vx-col md:w-1/5 w-full text-warning">เครดิตฟรี</div>
    <div class="vx-col md:w-1/5 w-full text-warning">{{rpbonus.countcreditfree}} ครั้ง</div>
    <div class="vx-col md:w-1/5 w-full text-warning">{{rpbonus.countusercreditfree}} คน</div>
    <div class="vx-col md:w-1/5 w-full text-warning">{{rpbonus.sumcreditfree}} Credit</div>
  </div>
  <br>
  <br>
  <div class="vx-row" >
    <div class="vx-col md:w-1/5 w-full text-success">ranking</div>
    <div class="vx-col md:w-1/5 w-full text-success">{{rpbonus.countranking}} ครั้ง</div>
    <div class="vx-col md:w-1/5 w-full text-success">{{rpbonus.countuserranking}} คน</div>
    <div class="vx-col md:w-1/5 w-full text-success">{{rpbonus.sumranking}} Credit</div>
    <div class="vx-col md:w-1/5 w-full text-warning"><vs-button :disabled="$store.state.AppActiveUser.permissions.report.action ? false : true" @click="activePrompt8 = true">Export</vs-button></div>
  </div>
  <br>
  <!-- <br>
  <div class="vx-row" >
    <div class="vx-col md:w-1/5 w-full text-danger">happytime</div>
    <div class="vx-col md:w-1/5 w-full text-danger">{{rpbonus.counthappytime}} ครั้ง</div>
    <div class="vx-col md:w-1/5 w-full text-danger">{{rpbonus.countuserhappytime}} คน</div>
    <div class="vx-col md:w-1/5 w-full text-danger">{{rpbonus.sumhappytime}} Credit</div>
    <div class="vx-col md:w-1/5 w-full text-warning"><vs-button :disabled="$store.state.AppActiveUser.permissions.report.action ? false : true" @click="activePrompt8 = true">Export</vs-button></div>
  </div> -->
  </vx-card>
  </div>
</template>

<script>
import axios from '../../axios'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import vSelect from 'vue-select'


export default {
  components: {
    flatPickr,
    StatisticsCardLine,
    vSelect
  },
  data () {
    return {
      webname: process.env.VUE_APP_TITLE,
      before_datetime: moment.tz('Asia/Bangkok').format('YYYY-MM-DD 00:00'),
      after_datetime: moment.tz('Asia/Bangkok').add(1, 'days').format('YYYY-MM-DD HH:mm'),
      configdateTimePicker: {
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        time_24hr: true
      },
      rpbonus:{},
      data:[],
      activePrompt: false,
      activePrompt1: false,
      activePrompt2: false,
      activePrompt3: false,
      activePrompt4: false,
      activePrompt5: false,
      activePrompt6: false,
      activePrompt7: false,
      activePrompt8: false,
      activePrompt9: false,
      activePrompt14: false,
      activePrompt15: false,
      activePrompt16: false,
      activePrompt17: false,
      activePrompt18: false,
      activePrompt19: false,
      activePrompt20: false,
      activePrompt21: false,
      activePrompt22: false,
      activePrompt23: false,
      fileName: '',
      cellAutoWidth: true,
      formats:['xlsx', 'csv', 'txt'],
      selectedFormat: 'xlsx',
      headerTitle: ['Username', 'เบอร์โทรศัพท์', 'LINE'],
      headerVal: ['topup_username', 'member_phone', 'member_line']


    }
  },
  async mounted () {
    await axios
      .get(`reportpromotion/bonus/${this.before_datetime }/${ this.after_datetime}`)
      .then(response => (this.rpbonus = response.data))
    await axios
      .get(`reportpromotion/databonus/${this.before_datetime }/${ this.after_datetime}`)
      .then(response => (this.data = response.data))
    this.fileName = `Report_Bonus ${this.before_datetime} ${this.after_datetime}`
  },
  methods: {
    async selectdate_time () {
      await axios
        .get(`reportpromotion/bonus/${this.before_datetime }/${ this.after_datetime}`)
        .then(response => (this.rpbonus = response.data))
      await axios
        .get(`reportpromotion/databonus/${this.before_datetime }/${ this.after_datetime}`)
        .then(response => (this.data = response.data))
      this.fileName = `Report_Bonus ${this.before_datetime} ${this.after_datetime}`
    },
    exportToExcel (sum_data) {
      import('@/vendor/Export2Excel').then(excel => {
        const list = sum_data
        const data = this.formatJson(this.headerVal, list)
        excel.export_json_to_excel({
          header: this.headerTitle,
          data,
          filename: this.fileName,
          autoWidth: this.cellAutoWidth,
          bookType: this.selectedFormat
        })

        this.clearFields()
      })
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        // Add col name which needs to be translated
        // if (j === 'timestamp') {
        //   return parseTime(v[j])
        // } else {
        //   return v[j]
        // }

        return v[j]
      }))
    },
    clearFields () {
      this.filename = ''
      this.cellAutoWidth = true
      this.selectedFormat = 'xlsx'
    }

  }
}

</script>

<style>

</style>
